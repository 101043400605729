import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  loaded = false

  static values = {
    loadUsersUrl: String,
    likesCount: Number
  }

  _timer

  onMouseLeave() {
    clearTimeout(this._timer)
    this.element.classList.remove('show-popover')
  }

  loadUsersPreview() {
    this._timer = setTimeout(() => {
      this.element.classList.add('show-popover')
    }, 500);

    if (this.likesCountValue === 0) { return }

    if (!this.loaded) {
      this.loaded = true

      get(this.loadUsersUrlValue, {
        contentType: 'text/vnd.turbo-stream.html',
        responseKind: 'turbo-stream'
      })
    }
  }
}
